import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import find from 'lodash/find';
import { LinearProgress, WithStyles } from '@material-ui/core';
import * as singlespa from 'single-spa';
import withUser, { WithUserProps } from '../../components/Login/consumer';
import registerReactApp from '../../spa';
import { ChangeTitle } from '../../redux/Root/actions';
import Style from './style';
import { MeraDmartTheme } from '../../constants/theme';
import { ApplicationInfo } from '../../spa/types';
import { Exceptions } from '../../constants/exceptions';
import { APIErrorChild } from '../../utils/exceptions';
import { generateStyle } from '../../utils/functions';
import { ApplicationsState, GET_APPS } from '../../redux/Home/types';
import { RootState } from '../../reducers';
import { ActionStatus } from '../../constants/api';

interface ChildRouterParams {
	id: string;
}
export interface ChildComponentProps
	extends RouteComponentProps<ChildRouterParams>,
		WithUserProps,
		WithStyles<typeof Style> {
	applications: ApplicationsState;
	getApps: typeof GET_APPS.action;
	changeMenuTitle: typeof ChangeTitle;
}

interface ChildComponentState {
	loading: boolean;
}
/**
 * Render Child
 */
export class ChildComponent extends React.Component<ChildComponentProps, ChildComponentState> {
	/**
	 * Constructor
	 * @param {IMenuProps} props - Properties.
	 * @returns {void} Contructor.
	 */
	constructor(props: ChildComponentProps) {
		super(props);
		this.state = {
			loading: true
		};
	}

	/**
	 * Component did Mount.
	 * @returns {void}
	 */
	componentDidMount = async () => {
		const { match, getApps, applications } = this.props;
		// Child  API Error Management
		window.addEventListener(Exceptions.APIError, (evt) => {
			const APIError = get(evt, 'detail', {});

			APIErrorChild(APIError);
		});
		window.addEventListener('single-spa:app-change', (evt) => {
			const MountedApps = get(evt, 'detail.appsByNewStatus.MOUNTED', []);
			if (MountedApps.includes(match.params.id)) {
				this.setState({ loading: false });
			}
		});
		window.addEventListener('single-spa:routing-event', (evt: any) => {
			const MountedApps = get(evt, 'detail.appsByNewStatus.MOUNTED', []);
			if (MountedApps.includes(match.params.id)) {
				this.setState({ loading: false });
			}
		});

		singlespa.addErrorHandler((err) => {
			console.log(err.appOrParcelName);
		});
		if (applications.getApps !== ActionStatus.SUCCESS) {
			getApps();
		}
		if (applications.getApps === ActionStatus.SUCCESS) {
			this.startApp();
		}
	};

	componentDidUpdate(prevProps: ChildComponentProps) {
		const { user, applications } = this.props;
		const prevApps = prevProps.applications;
		const prevToken = get(prevProps, 'token.accessToken', '');
		const currentToken = get(this.props, 'token.accessToken', '');
		if (prevToken !== currentToken) {
			const { changeMenuTitle, match } = this.props;
			const userApps = get(applications, 'apps', []);
			const appInfo = find(userApps, (a) => a.applicationCode === match.params.id) || {};
			if (appInfo) {
				const title = get(appInfo, 'applicationTitle', '');
				document.title = `MeraDMart - ${title}`;
				const userMeta = get(user, 'siteId', '');
				const meta = trim(`${userMeta}`);
				changeMenuTitle({ title, meta });
			}
		}
		if (
			applications.getApps !== prevApps.getApps &&
			applications.getApps === ActionStatus.SUCCESS
		) {
			this.startApp();
		}
	}

	startApp = () => {
		const { applications, match } = this.props;
		const userApps = get(applications, 'apps', []);
		const userfind = find(userApps, (a) => a.applicationCode === match.params.id) || {};
		const appInfo: ApplicationInfo = userfind as ApplicationInfo;
		if (!isEmpty(appInfo)) {
			this.registerApplication(appInfo);
		}
	};

	registerApplication = (appInfo: ApplicationInfo) => {
		if (!isEmpty(appInfo)) {
			const { user, token, changeMenuTitle } = this.props;
			const title = get(appInfo, 'applicationTitle', '');
			document.title = `MeraDMart - ${title}`;
			const userMeta = get(user, 'siteId', '');
			const meta = trim(`${userMeta}`);
			changeMenuTitle({ title, meta });
			registerReactApp(appInfo, {
				user,
				token,
				theme: MeraDmartTheme
			});
		}
	};

	/**
	 * Component did Mount.
	 * @returns {void}
	 */
	componentWillUnmount = async () => {
		const { changeMenuTitle, match } = this.props;
		changeMenuTitle({ title: '', meta: '' });
		if (singlespa.getAppNames().includes(match.params.id)) {
			singlespa.unloadApplication(match.params.id);
			await singlespa.unregisterApplication(match.params.id);
		}
	};

	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { loading } = this.state;
		const { classes, match } = this.props;
		return (
			<>
				{loading && <LinearProgress color="primary" />}
				<div id={match.params.id} className={classes.root} />
			</>
		);
	}
}

export default React.memo(
	generateStyle(
		Style,
		'MDFChild'
	)(
		withRouter(
			withUser(
				connect(
					(state: RootState) => ({
						applications: state.applications
					}),
					{
						getApps: GET_APPS.action,
						changeMenuTitle: ChangeTitle
					}
				)(ChildComponent)
			)
		)
	)
);
