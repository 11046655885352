import get from 'lodash/get';
import { MDFEXPIRED } from '../constants/events';
import { AuthEvents } from '../events';

export const APIError = (error) => {
	const status = get(error, 'response.status', 0);
	// if (status === 401) {
	// AuthEvents.emit(MDFEXPIRED);
	// }
};
export const APIErrorChild = (error) => {
	const status = get(error, 'response.status', 0);
	const childStatus = get(error, 'status', 0);
	// if (status === 401 || childStatus === 401) {
	// AuthEvents.emit(MDFEXPIRED);
	// }
};
