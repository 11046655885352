/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */

import * as singleSpa from 'single-spa';
import get from 'lodash/get';
import trimEnd from 'lodash/trim';
import { ajax } from 'rxjs/ajax';
import { matchingPathname, runScript } from './utils';
import { ChildProps, ApplicationInfo } from './types';
import { ASSETS } from './variables';

const { REACT_APP_URL = '' } = process.env;
declare global {
	interface Window {
		reactApp: any;
	}
}

/**
 * @param {any} appInfo - Properties.
 * @returns {void}
 */
const loadReactApp = async (appInfo: ApplicationInfo) => {
	try {
		const url = get(appInfo, 'applicationIndex', '');
		const trimUrl = [REACT_APP_URL, trimEnd(url, '/')].join('/');
		const code = get(appInfo, 'applicationCode', '');
		const assets = await ajax({
			url: `${trimUrl}/${ASSETS}?_=${new Date().getTime()}`,
			method: 'GET',
			crossDomain: true
		})
			.toPromise()
			.then((response: any) => {
				return response.response;
			});
		const jsFiles = assets.entrypoints.filter((t) => t.endsWith('.js'));
		const promise: Array<any> = [];
		const last = jsFiles[jsFiles.length - 1];
		for (let index = 0; index < jsFiles.length - 1; index++) {
			const element = jsFiles[index];
			promise.push(runScript(`${[trimUrl, element].join('/')}`));
		}
		await Promise.all(promise);
		await runScript(`${[trimUrl, last].join('/')}`);
		return window[code];
	} catch (error) {
		return error;
	}
};

/**
 * @param {any} appInfo - AppInfo.
 * @param {any} extraParams - extraParams.
 * @returns {void}
 */
const registerReactApp = async (appInfo: ApplicationInfo, extraParams: ChildProps) => {
	try {
		if (!singleSpa.getAppNames().includes(appInfo.applicationCode)) {
			singleSpa.registerApplication(
				appInfo.applicationCode,
				loadReactApp(appInfo) as any,
				matchingPathname([`/app/${appInfo.applicationCode}`]),
				extraParams
			);
		}
		return true;
	} catch (error) {
		return false;
	}
};

export default registerReactApp;
