import trimStart from 'lodash/trimStart';
/**
 *
 * @param {any} url - x
 * @returns {void}
 */
export const runScript = async (url) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = url;
		script.onload = resolve;
		script.onerror = reject;
		const firstScript = document.getElementsByTagName('script')[0];
		if (firstScript) {
			if (firstScript.parentNode) {
				firstScript.parentNode.insertBefore(script, firstScript);
			}
		}
	});
};

/**
 *
 * @param {any} pathnames - x
 * @returns {void}
 */
export const matchingPathname = (pathnames) => (location) => {
	const path = location.hash.replace('#', '');
	return pathnames.some((pathName) => {
		const trimName = trimStart(pathName, '/');
		const trimLocation = trimStart(location.pathname, '/');
		const trimHash = trimStart(path, '/');
		const bool = trimHash.startsWith(trimName) || trimLocation.startsWith(trimName);
		return bool;
	});
};
